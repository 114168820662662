exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ai-development-tsx": () => import("./../../../src/pages/ai-development.tsx" /* webpackChunkName: "component---src-pages-ai-development-tsx" */),
  "component---src-pages-ai-service-tsx": () => import("./../../../src/pages/ai-service.tsx" /* webpackChunkName: "component---src-pages-ai-service-tsx" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-data-engineering-tsx": () => import("./../../../src/pages/data-engineering.tsx" /* webpackChunkName: "component---src-pages-data-engineering-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-whitepapers-js": () => import("./../../../src/pages/whitepapers.js" /* webpackChunkName: "component---src-pages-whitepapers-js" */),
  "component---src-templates-whitepapers-js": () => import("./../../../src/templates/whitepapers.js" /* webpackChunkName: "component---src-templates-whitepapers-js" */)
}

